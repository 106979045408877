/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from "react";

function useActiveTabEffect(callback, deps = []) {
  const isActive = useRef(!document.hidden);
  const isLoaded = useRef(false);

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden && !isLoaded.current) {
      console.log("hidden");
      isActive.current = true;
      isLoaded.current = true;
      callback();
    } else {
      isActive.current = false;
    }
  }, [callback]);


  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  useEffect(() => {
    const callCallback = () => {
      if (isActive.current && !isLoaded.current) {
        isLoaded.current = true;
        callback();
      } else if (isLoaded.current) {
        callback();
      }
    };

    callCallback();
  }, [...deps]);
}

export default useActiveTabEffect;
