/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import { isCandidateCanRetakeVideo, isFlagRetakePromptsEnabled, isRetakePromptsEnabled } from "mixins/helperVideoRecording";
import { Reload } from "mixins/svgIcons";
import { BeatLoader, ClipLoader } from "react-spinners";
import { isEmpty } from "lodash";
import { isIOS } from "react-device-detect";
import { ANSWER_TYPES } from "configs/jobs/constants";
import { getBorderColorContrastOrHex, getColorContrastOrHex, getFontColorFromBgColor } from "mixins/helperDynamicStyles";
import { store } from "store";
import { getCandidateJobInfo } from "store/modules/сandidates/selectors";

const Buttons = ({
  t,
  isRecording,
  isCompleted,
  blobUrl,
  btnsDisabled,
  startRecord,
  stopRecord,
  finishRecord,
  memorizedCompanyInfo,
  max_retakes: maxRetakes,
  attempt_number: attemptNumber,
  isBlobEmpty,
  remoteLink,
  navigateToNextStep,
  isShowDelayMessage,
  isSuccessRecord,
  isMediaBlocked,
  handleReRecord,
  onlyAudio,
  isLoadingVisible,
  showLoadingBtn,
  isTestMode,
  hasUploadedMedia,
  noAvailableRetakes,
  shouldShowMissingMedia,
  isMediaUploadInterrupted,
  hasEBML = false
}) => {
  const handleStartRecord = () => startRecord();
  const isCanRetakeVideo = isCandidateCanRetakeVideo(attemptNumber, maxRetakes);

  const shouldJustNavigate = isRetakePromptsEnabled
    ? false
    : navigateToNextStep && remoteLink && !blobUrl;

  const handleStopRecord = () => {
    showLoadingBtn();
    stopRecord();
  };

  let hasNoRecordedVideo = useMemo(() => (!blobUrl || isBlobEmpty || (isIOS && isFlagRetakePromptsEnabled
    ? isEmpty(remoteLink) : false)), [blobUrl, isBlobEmpty, remoteLink]);

  if (isRetakePromptsEnabled && !isTestMode) {
    hasNoRecordedVideo = isIOS ? isEmpty(remoteLink) && (!blobUrl || isBlobEmpty)
      : (isEmpty(remoteLink) && !hasUploadedMedia);
  }

  if (isRetakePromptsEnabled && !(noAvailableRetakes || shouldShowMissingMedia) && !isBlobEmpty) hasNoRecordedVideo = false;

  const isUploadErrorShown = shouldShowMissingMedia || isMediaUploadInterrupted;

  const handleSaveAndContinue = () => {
    showLoadingBtn();
    if (shouldJustNavigate) {
      navigateToNextStep();
    } else {
      finishRecord(noAvailableRetakes, isUploadErrorShown);
    }
  };

  const commonCompany = getCandidateJobInfo(store.getState());
  const color = getFontColorFromBgColor({ hex: commonCompany?.company?.button_color }) ?? "#FFF";

  const defaultStyle = memorizedCompanyInfo.button_color
    ? {
      background: memorizedCompanyInfo.button_color,
      color
    }
    : { color };

  const errorStyle = { background: "#768493", color: "#FFF" };

  const isDisabled = useMemo(() =>
    (!(btnsDisabled.btnStart && btnsDisabled.btnStop) &&
    shouldJustNavigate ? false : hasNoRecordedVideo) || isShowDelayMessage,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [btnsDisabled, hasNoRecordedVideo, isShowDelayMessage, shouldJustNavigate]);

  return (
    <>
      {!isRecording && !isCompleted && !noAvailableRetakes && (
        <div className="button__wrapper">
          <button
            className="
                n-fluid
                n-button__large
                n-bg-purple-100
              "
            style={isSuccessRecord ? defaultStyle : errorStyle}
            type="button"
            disabled={
              btnsDisabled.btnStart || isShowDelayMessage || isMediaBlocked || isLoadingVisible || !hasEBML
            }
            onClick={handleStartRecord}
            data-testid="Interview-Btn-StartRecording_Btn"
            aria-label="Start Recording"
          >
            {onlyAudio
              ? (<i className={`fa mr15 white ${ANSWER_TYPES.audio.icon}`}>{ANSWER_TYPES.audio.reactIcon}</i>)
              : (<i className={`fa mr15 white ${ANSWER_TYPES.video.icon}`}>{ANSWER_TYPES.video.reactIcon}</i>)
            }
            {t("button.recordStart")}
          </button>
        </div>
      )}

      {
        isRecording && !isCompleted && btnsDisabled?.btnLoading && (
          <div className="button__wrapper">
            <button
              className="
                  n-fluid
                  n-button__large
                  n-bg-red-100
                  n-white
                "
              type="button"
              disabled
              data-testid="Interview-Btn-DisabledLoading_Btn"
              aria-label="Loading"
            >
              <div style={{ marginRight: 15 }}>
                <ClipLoader
                  color="#FFF"
                  size={18}
                  margin={1}
                  loading
                />
              </div>
              {t("button.loading")}
            </button>
          </div>
        )
      }

      {isRecording && !isCompleted && !btnsDisabled?.btnLoading && (
        <div className="button__wrapper">
          <button
            className="
              n-fluid
              n-button__large
              n-bg-red-100
              n-white
            "
            type="button"
            disabled={btnsDisabled.btnStop}
            onClick={handleStopRecord}
            data-testid="Interview-Btn-StopRecording_Btn"
            aria-label="Stop Recording"
          >
            {t("button.recordStop")}
          </button>
        </div>
      )}

      {!isRecording && isCompleted && (
        <div
          className={classNames({
            button__group: isCanRetakeVideo,
            button__wrapper: !isCanRetakeVideo
          })}
        >
          {isCanRetakeVideo ? (
            <button
              style={
                memorizedCompanyInfo && {
                  borderColor: getBorderColorContrastOrHex({
                    hex: memorizedCompanyInfo.button_color
                  }),
                  color: getColorContrastOrHex({
                    hex: memorizedCompanyInfo.button_color
                  })
                }
              }
              className="
                n-x-lg
                n-button__large-border
                n-border-purple-100
                n-purple-100
                flex flex-row items-center justify-center
              "
              type="button"
              disabled={btnsDisabled.btnStart || !hasEBML}
              onClick={() => {
                if (typeof handleReRecord === "function") return handleReRecord();
                return startRecord(isIOS ? true : undefined);
              }}
              data-testid="Interview-Btn-Rerecord_Btn"
              aria-label="Rerecord"
            >
              <Reload
                svgIconClass="n-icon__large right"
                fill={getColorContrastOrHex({
                  hex: memorizedCompanyInfo.button_color
                })}
              />
              {t("button.reRecord")}
            </button>
          ) : (
            <span />
          )}
          <button
            className={classNames({
              "n-button__large n-bg-purple-100 n-white": true,
              "n-x-lg": isCanRetakeVideo,
              "n-fluid": !isCanRetakeVideo
            })}
            style={
              memorizedCompanyInfo && {
                background: memorizedCompanyInfo.button_color,
                color: getFontColorFromBgColor({
                  hex: memorizedCompanyInfo.button_color
                })
              }
            }
            type="button"
            disabled={isDisabled}
            onClick={handleSaveAndContinue}
            data-testid="Interview-Btn-SaveAndContinue_Btn"
            aria-label="Save and Continue"
          >
            {
              btnsDisabled?.btnLoading ? (
                <div style={{ marginRight: 15 }}>
                  <BeatLoader color={color} size={6} margin={1} loading />
                </div>
              ) : null
            }
            {!btnsDisabled?.btnLoading ? t("button.saveContinue") : null}
          </button>
        </div>
      )}

      {
        noAvailableRetakes && isBlobEmpty && !isCompleted ? (
          <div
            className={classNames({
              button__group: isCanRetakeVideo,
              button__wrapper: !isCanRetakeVideo
            })}
          >
            <button
              className={classNames({
                "n-button__large n-bg-purple-100 n-white": true,
                "n-x-lg": isCanRetakeVideo,
                "n-fluid": !isCanRetakeVideo
              })}
              style={
                memorizedCompanyInfo && {
                  background: memorizedCompanyInfo.button_color,
                  color
                }
              }
              type="button"
              disabled={isShowDelayMessage}
              onClick={handleSaveAndContinue}
              data-testid="Interview-Btn-SaveAndContinue_Btn"
              aria-label="Save and Continue"
            >
              {
                btnsDisabled?.btnLoading ? (
                  <div style={{ marginRight: 15 }}>
                    <BeatLoader color={color} size={6} margin={1} loading />
                  </div>
                ) : null
              }
              {!btnsDisabled?.btnLoading ? t("button.saveContinue") : null}
            </button>
          </div>
        ) : null
      }
    </>
  );
};

Buttons.defaultProps = {
  isRecording: false,
  isCompleted: false,
  blobUrl: undefined,
  btnsDisabled: {},
  memorizedCompanyInfo: undefined,
  startRecord: undefined,
  stopRecord: undefined,
  finishRecord: undefined,
  max_retakes: undefined,
  attempt_number: undefined,
  isBlobEmpty: undefined,
  navigateToNextStep: undefined,
  remoteLink: undefined,
  isShowDelayMessage: undefined,
  handleReRecord: undefined,
  isLoadingVisible: false,
  isTestMode: undefined,
  hasUploadedMedia: false,
  noAvailableRetakes: false,
  shouldShowMissingMedia: false,
  isMediaUploadInterrupted: false,
  hasEBML: false
};

Buttons.propTypes = {
  t: PropTypes.func.isRequired,
  isRecording: PropTypes.bool,
  isCompleted: PropTypes.bool,
  blobUrl: PropTypes.string,
  btnsDisabled: PropTypes.shape({
    btnStart: PropTypes.bool,
    btnStop: PropTypes.bool,
    btnLoading: PropTypes.bool
  }),
  memorizedCompanyInfo: PropTypes.shape({
    button_color: PropTypes.string
  }),
  startRecord: PropTypes.func,
  stopRecord: PropTypes.func,
  finishRecord: PropTypes.func,
  max_retakes: PropTypes.number,
  attempt_number: PropTypes.number,
  isBlobEmpty: PropTypes.bool,
  navigateToNextStep: PropTypes.func,
  remoteLink: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  isShowDelayMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isSuccessRecord: PropTypes.bool.isRequired,
  isMediaBlocked: PropTypes.bool.isRequired,
  onlyAudio: PropTypes.bool.isRequired,
  handleReRecord: PropTypes.func,
  isLoadingVisible: PropTypes.bool,
  showLoadingBtn: PropTypes.func.isRequired,
  isTestMode: PropTypes.bool,
  hasUploadedMedia: PropTypes.bool,
  noAvailableRetakes: PropTypes.bool,
  shouldShowMissingMedia: PropTypes.bool,
  isMediaUploadInterrupted: PropTypes.bool,
  hasEBML: PropTypes.bool
};

export default withTranslation()(Buttons);
