/* eslint-disable import/no-cycle */
import { AxiosInstance } from "./interceptors";

const apiRequest = options => {
  const { fullUrl = "", url = "" } = options;

  return AxiosInstance({
    method: "GET",
    ...options,
    url: fullUrl || `${url}`,
    baseURL: fullUrl ? undefined : process.env.REACT_APP_BACKEND_URL
  }).then(response => response);
};

export const getRequest = ({
  fullUrl = "",
  url = "",
  headers,
  responseType,
  ...rest
}) => {
  const data = rest.payload && {
    ...rest.payload
  };

  const options = {};

  if (rest?.cancelTokenSource?.token) {
    options.cancelToken = rest.cancelTokenSource.token;
  }

  return apiRequest({
    fullUrl,
    url,
    data,
    headers,
    responseType,
    ...options
  });
};

export const headRequest = ({ fullUrl, ...rest }) => {
  const data = rest.payload && {
    ...rest.payload
  };

  return apiRequest({
    method: "HEAD",
    fullUrl,
    data
  });
};

export const postRequest = ({ fullUrl = "", url = "", headers, ...rest }) => {
  const data = rest.payload && {
    ...rest.payload
  };

  const options = {};

  if (rest?.cancelTokenSource?.token) {
    options.cancelToken = rest.cancelTokenSource.token;
  }

  return apiRequest({
    method: "POST",
    fullUrl,
    url,
    data,
    headers,
    ...options
  });
};

export const getRequestWithToken = ({ url, token, ...rest }) => {
  const data = rest.payload && {
    ...rest.payload
  };

  const options = {
    headers: { authorization: `Bearer ${token}` },
    url,
    data,
    params: rest.params,
    responseType: rest?.responseType || ""
  };

  if (rest?.cancelTokenSource?.token) {
    options.cancelToken = rest.cancelTokenSource.token;
  }

  return apiRequest(options);
};

export const getFullUrlRequestWithToken = ({ fullUrl, token, ...rest }) => {
  const data = rest.payload && {
    ...rest.payload
  };

  const options = {
    headers: { authorization: `Bearer ${token}` },
    fullUrl,
    data,
    params: rest.params,
    responseType: rest?.responseType || ""
  };

  if (rest?.cancelTokenSource?.token) {
    options.cancelToken = rest.cancelTokenSource.token;
  }

  return apiRequest(options);
};

export const postRequestWithToken = ({ url, token, ...rest }) => {
  const data = rest.payload && {
    ...rest.payload
  };

  const options = {
    headers: { authorization: `Bearer ${token}` },
    method: "POST",
    url,
    data
  };

  if (typeof rest?.onUploadProgress === "function") {
    options.onUploadProgress = progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      rest.onUploadProgress(percentCompleted);
    };
  }

  if (rest?.cancelTokenSource?.token) {
    options.cancelToken = rest.cancelTokenSource.token;
  }

  return apiRequest(options);
};

export const patchRequestWithToken = ({ url, token, ...rest }) => {
  const data = rest.payload && {
    ...rest.payload
  };

  const options = {};

  if (rest?.cancelTokenSource?.token) {
    options.cancelToken = rest.cancelTokenSource.token;
  }

  return apiRequest({
    headers: { authorization: `Bearer ${token}` },
    method: "PATCH",
    url,
    data,
    ...options
  });
};

export const uploadRequestWithToken = ({
  url,
  fullUrl,
  token,
  method,
  ...rest
}) => {
  const formData = new FormData();

  Object.keys(rest.payload).forEach(key => {
    const currentValue = rest.payload[key] || "";

    if (key === "logo" && rest.payload[key] === null) return;

    formData.append(key, currentValue);
  });

  let response = null;

  if (fullUrl) {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      method,
      fullUrl,
      data: formData
    };

    if (typeof rest?.onUploadProgress === "function") {
      options.onUploadProgress = progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        rest.onUploadProgress(percentCompleted);
      };
    }

    if (rest?.cancelTokenSource?.token) {
      options.cancelToken = rest.cancelTokenSource.token;
    }

    response = apiRequest(options);
  } else {
    const options = {};

    if (rest?.cancelTokenSource?.token) {
      options.cancelToken = rest.cancelTokenSource.token;
    }

    response = apiRequest({
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      },
      method,
      url,
      data: formData,
      ...options
    });
  }

  return response;
};

export const putRequestWithToken = ({ url, token, ...rest }) => {
  const data = rest.payload && {
    ...rest.payload
  };

  const options = {};

  if (rest?.cancelTokenSource?.token) {
    options.cancelToken = rest.cancelTokenSource.token;
  }

  return apiRequest({
    headers: { authorization: `Bearer ${token}` },
    method: "PUT",
    url,
    data,
    ...options
  });
};

export const deleteRequestWithToken = ({ url, token, ...rest }) => {
  const data = rest.payload && {
    ...rest.payload
  };

  const options = {};

  if (rest?.cancelTokenSource?.token) {
    options.cancelToken = rest.cancelTokenSource.token;
  }

  return apiRequest({
    headers: { authorization: `Bearer ${token}` },
    method: "DELETE",
    url,
    data,
    ...options
  });
};
