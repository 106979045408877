/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable function-paren-newline */
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import countries from "i18n-iso-countries";

import {
  INITIAL_DURATION,
  INITIAL_RETAKES,
  INITIAL_TYPE,
  INITIAL_LIMIT,
  INITIAL_MAX,
  TEXT_LIMITS,
  IDV_TYPE,
  IDV_INCLUDED_TYPES,
  TYPES,
  RTW_TYPE,
  ALL_IDENTITY_VERIFICATION_TYPES,
  IDV_CHECK
} from "configs/jobs/constants";
import { DATE_FORMATS } from "configs/dates";
import * as Sentry from "@sentry/browser";
import { getIsStorageSupported } from "./helperCandidate";

const QUESTION = "question";
const REVIEWR = "reviewer";
const DESCRIPTION = "description";
const CONTENT = "content";
const MAX_NUMBER = 32767;

export const isEmpty = value =>
  typeof value === "undefined" || value === null || value === false;

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties
});

export const checkValidity = (value, rules, compareValue) => {
  let isValid = true;

  if (!rules) {
    return true;
  }

  if (rules.required) {
    if (typeof value === "string") {
      isValid = value?.trim() !== "" && isValid;
    }
  }

  if (rules.maxNumber) {
    isValid = value <= MAX_NUMBER && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.maxLength <= rules.maxLength && isValid;
  }

  if (rules.isTheSame) {
    isValid = value === compareValue && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (value.length > 0) {
      isValid = pattern.test(value) && isValid;
    }
  }

  if (rules.isVideoUrl) {
    // eslint-disable-next-line no-useless-escape
    const pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    if (value && value.length > 0) {
      isValid = pattern.test(value) && isValid;
    }
  }

  if (rules.isRedirectUrl) {
    // eslint-disable-next-line no-useless-escape
    const pattern = /(http|https):\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;

    if (value && value.length > 0) {
      isValid = pattern.test(value?.trim()) && isValid;
    }
  }

  if (rules.isPassword) {
    // eslint-disable-next-line no-useless-escape
    const pattern = /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = new RegExp("^\\d+$");
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const normalizeChatsTitlesData = data => {
  const chatTitles = [];
  data.forEach(elem =>
    chatTitles.push({ id: elem.id, chatName: elem.data().chatName })
  );
  return chatTitles;
};

export const normalizeChatsMessagesData = data => {
  const chatMessages = [];
  data.forEach(elem =>
    chatMessages.push({
      id: elem.id,
      message: elem.data().message,
      userId: elem.data().userId
    })
  );

  return chatMessages;
};

// Function to encode surrogate pairs
export function encodeSurrogatePairs(str = "") {
  return str?.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, pair => {
    const codePoint = pair.codePointAt(0);
    return ` U+${codePoint.toString(16)} `;
  });
}

// Function to decode encoded surrogate pairs
export function decodeSurrogatePairs(str = "") {
  return str?.replace(/U\+([0-9a-fA-F]+)/g, (_, hex) => {
    const codePoint = parseInt(hex, 16);
    return String.fromCodePoint(codePoint);
  });
}
export const getEventValue = (event, type) => {
  let inputValue = null;
  switch (type) {
  case "checkbox":
    inputValue = event.target.checked;
    break;
  case "select":
    inputValue = event;
    break;
  case "texteditor":
    inputValue = event;
    break;
  case "prefilled":
    inputValue = event;
    break;
  default:
    inputValue = event.target.value;
  }

  return inputValue;
};

export const getValueForCheck = (eventValue, type) => {
  let valueForCheck = null;

  switch (type) {
  case "select":
    valueForCheck = eventValue && eventValue.value;
    break;
  case "texteditor":
    valueForCheck = eventValue.getCurrentContent().getPlainText();
    break;
  default:
    valueForCheck = eventValue;
  }
  return valueForCheck;
};

// eslint-disable-next-line consistent-return
export const predefilledWithQuestion = (predefinedInfo, key = "") => {
  const keyForExistingQuestion = predefinedInfo[key].key || "";

  if (key.startsWith(QUESTION)) {
    const {
      value,
      max_duration,
      max_retakes,
      order,
      answer_type,
      thinking_time: thinkingTime,
      max_characters: maxCharacters = null,
      max_words: maxWords = INITIAL_MAX,
      idv_country_of_employment_iso_code: countryOfEmployment,
      idv_max_checks: idvMaxChecks
    } = predefinedInfo[key];
    const minutes = thinkingTime ? Math.floor(thinkingTime / 60) : null;

    const questionTemplate = {
      label: "jobQuestionTitle",
      infoLabel: "jobQuestionInfo",
      elementType: "texteditor",
      elementConfig: {
        placeholder: "jobQuestionPlaceholder",
        type: "texteditor",
        rows: "5",
        cols: "45"
      },
      order,
      value,
      max_duration,
      max_retakes,
      answer_type,
      thinking_minutes: minutes ? minutes % 60 : null,
      thinking_hours: minutes ? Math.floor(minutes / 60) : null,
      thinking_time: thinkingTime,
      idv_country_of_employment_iso_code: countries.alpha3ToAlpha2(
        countryOfEmployment
      ),
      idv_max_checks: idvMaxChecks,
      max_count: maxWords || maxCharacters,
      text_limit: maxCharacters ? TEXT_LIMITS[0].value : TEXT_LIMITS[1].value,
      key: keyForExistingQuestion,
      validation: {
        required: true
      },
      prefix: "overview",
      errorMessage: "jobQuestionError",
      valid: false,
      touched: false
    };
    return questionTemplate;
  }
};

// eslint-disable-next-line consistent-return
export const predefilledWithReviwers = (predefinedInfo, key) => {
  if (key.startsWith(REVIEWR)) {
    const { value, order } = predefinedInfo[key];
    const reviewerTemplate = {
      label: `Reviewer ${order}`,
      elementType: "input",
      elementConfig: {
        placeholder: "Reviewer email address",
        type: "input"
      },
      order,
      value,
      validation: {
        isEmail: false
      },
      prefix: "overview",
      valid: true,
      touched: false
    };

    return reviewerTemplate;
  }
};

export const prefilledEditor = currentValue => {
  try {
    const blocksFromHtml = htmlToDraft(decodeSurrogatePairs(currentValue));
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return EditorState.createWithContent(contentState);
  } catch (e) {
    Sentry.captureException(e?.message || e);

    const blocksFromHtml = htmlToDraft(
      "<p>Response missing. If this is unexpected, please contact support@willo.video</p>"
    );
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );

    return EditorState.createWithContent(contentState);
  }
};

export const prefilledInfo = (currentInfo, predefinedInfo = []) => {
  if (!Object.values(predefinedInfo).filter(Boolean).length) return;
  let updatedInfo = currentInfo;

  Object.keys(predefinedInfo).forEach(key => {
    let currentValue = predefinedInfo[key];

    if (key.startsWith(QUESTION)) {
      updatedInfo[key] = predefilledWithQuestion(predefinedInfo, key);
    }

    if (key.startsWith(REVIEWR)) {
      updatedInfo[key] = predefilledWithReviwers(predefinedInfo, key);
    }

    if (key.startsWith(QUESTION)) {
      currentValue = predefinedInfo[key].html_text;
    }

    if ([DESCRIPTION, CONTENT].includes(key) || key.startsWith(QUESTION)) {
      currentValue = prefilledEditor(currentValue);
    }

    const currentType = updatedInfo[key].elementType;
    const currentValidation = updatedInfo[key].validation;
    const valueForCheck = getValueForCheck(currentValue, currentType);

    updatedInfo = updateObject(updatedInfo, {
      [key]: updateObject(updatedInfo[key], {
        value: currentValue,
        valid: checkValidity(valueForCheck, currentValidation),
        touched: true
      })
    });
  });

  // eslint-disable-next-line consistent-return
  return updatedInfo;
};

export const generateQuestion = (list, number = 1, type = INITIAL_TYPE) => {
  const questionTemplate = {
    [`question_${number}`]: {
      label: "jobQuestionTitle",
      infoLabel: "jobQuestionInfo",
      elementType: "texteditor",
      elementConfig: {
        placeholder: "jobQuestionPlaceholder",
        type: "texteditor",
        rows: "5",
        cols: "45"
      },
      order: number,
      value:
        type !== IDV_TYPE
          ? EditorState.createEmpty()
          : prefilledEditor(`<p>${IDV_CHECK}</p>`),
      max_duration: INITIAL_DURATION,
      max_retakes: INITIAL_RETAKES,
      thinking_hours: null,
      thinking_minutes: null,
      thinking_time: null,
      answer_type: type,
      key: "",
      validation: {
        required: true
      },
      prefix: "overview",
      errorMessage: "jobQuestionError",
      valid: false,
      touched: false,
      max_count: INITIAL_MAX,
      text_limit: INITIAL_LIMIT,
      idv_country_of_employment_iso_code: null,
      idv_max_checks: null
    }
  };

  list.push(questionTemplate);

  return list;
};

export const updateStateField = (eventValue, controls, controlName, field) => {
  const updatedControls = updateObject(controls, {
    [controlName]: updateObject(controls[controlName], {
      [field]: eventValue
    })
  });

  return updatedControls;
};

export const updateCurrentStateHandler = (
  event,
  controls,
  controlName,
  type
) => {
  const eventValue = getEventValue(event, type);
  const valueForCheck = getValueForCheck(
    controlName === "video" ? eventValue?.trim() : eventValue,
    type
  );

  const updatedControls = updateObject(controls, {
    [controlName]: updateObject(controls[controlName], {
      value: controlName === "video" ? eventValue?.trim() : eventValue,
      valid: checkValidity(valueForCheck, controls[controlName].validation),
      touched: true
    })
  });

  return updatedControls;
};

export const checkIfFormIsValid = (updatedControls, event = "") => {
  let formIsValidCurrent = true;
  Object.keys(updatedControls).forEach(key => {
    if (formIsValidCurrent) {
      formIsValidCurrent = updatedControls[key].valid && formIsValidCurrent;

      if (updatedControls[key].answer_type === IDV_TYPE || event === IDV_TYPE) {
        formIsValidCurrent = !isEmpty(
          updatedControls[key]?.idv_country_of_employment_iso_code
        );
      }
    }
  });

  return formIsValidCurrent;
};

/*
  Check if field into select menu is empty or not.
  Because user could open and close without chosing value.
*/

export const checkSelectValidity = (controlName, config) => {
  const { value, validation } = config[controlName];
  let updatedConfig = null;

  if (config[controlName].touched === false) {
    updatedConfig = updateObject(config, {
      [controlName]: updateObject(config[controlName], {
        valid: checkValidity(value, validation),
        touched: true
      })
    });
    return updatedConfig;
  }

  return config;
};

export const convertArrayToObject = listWithObjects => {
  const newObject = listWithObjects.reduce((result, item) => {
    const key = Object.keys(item)[0];
    result[key] = item[key];
    return result;
  }, {});

  return newObject;
};

export const addNewFieldsToControls = (list, number, controls) => {
  const objectWithFields = convertArrayToObject(list);
  const stateWithNewFields = updateObject(controls, objectWithFields);

  return stateWithNewFields;
};

export const getListOfControlFileds = (state, searchWord) =>
  Object.entries(state)
    .filter(key => key[0].startsWith(searchWord))
    .sort((a, b) => a[1].order - b[1].order)
    .reduce((acc, item) => {
      acc.push({ [item[0]]: item[1] });
      return acc;
    }, []);

export const controlsWithoutValues = (controls, value) =>
  Object.keys(controls)
    .filter(item => !item.startsWith(value))
    .reduce((obj, key) => {
      obj[key] = controls[key];
      return obj;
    }, {});

export const reorderAfterDelete = (controls, fieldLabelName, fieldName) => {
  const updatedControls = getListOfControlFileds(controls, fieldName).reduce(
    (result, item, index) => {
      const key = Object.keys(item)[0];
      const newValues = {
        label: "jobQuestionTitle",
        order: index + 1
      };

      const newReorderQuestion = updateObject(item[key], newValues);
      result[`${fieldName}_${index + 1}`] = newReorderQuestion;
      return result;
    },
    {}
  );

  const controlsWithoutQuestions = controlsWithoutValues(controls, QUESTION);

  return updateObject(controlsWithoutQuestions, updatedControls);
};

export const deleteControlsField = (
  controlName,
  controls,
  fieldLabelName,
  fieldName
) => {
  const updatedControls = Object.keys(controls)
    .filter(item => item !== controlName)
    .reduce((obj, key) => {
      obj[key] = controls[key];
      return obj;
    }, {});

  const reoderedQuestions = reorderAfterDelete(
    updatedControls,
    fieldLabelName,
    fieldName
  );

  return reoderedQuestions;
};

export const addControlsField = (question, controls) => {
  const fieldName = "question";
  const controlsArray = getListOfControlFileds(controls, fieldName);
  controlsArray.splice(question.order, 0, {
    [`${fieldName}_${question.order}`]: {
      ...question,
      valid: false,
      touched: false
    }
  });

  const updatedControls = controlsArray.reduce((result, item, index) => {
    const key = Object.keys(item)[0];
    const newValues = {
      label: "jobQuestionTitle",
      order: index + 1
    };

    const newReorderQuestion = updateObject(item[key], newValues);
    result[`${fieldName}_${index + 1}`] = newReorderQuestion;

    return result;
  }, {});

  const controlsWithoutQuestions = controlsWithoutValues(controls, QUESTION);

  return updateObject(controlsWithoutQuestions, updatedControls);
};

export const reoderQuestionsPosition = (order, direction, controls) => {
  const arrOfQuestions = getListOfControlFileds(controls, "question");
  const prevIndex = order - 1;
  let currentIndex = prevIndex;

  if (direction === "up") {
    currentIndex -= 1;
  }

  if (direction === "down") {
    currentIndex += 1;
  }

  const prevQuestion = arrOfQuestions[prevIndex][`question_${prevIndex + 1}`];

  const prevQuestionWithNewOrder = {
    [`question_${currentIndex + 1}`]: updateObject(prevQuestion, {
      label: "jobQuestionTitle",
      order: currentIndex + 1
    })
  };

  const currQuestion =
    arrOfQuestions[currentIndex][`question_${currentIndex + 1}`];

  const currQuestionWithNewOrder = {
    [`question_${prevIndex + 1}`]: updateObject(currQuestion, {
      label: "jobQuestionTitle",
      order: prevIndex + 1
    })
  };

  arrOfQuestions.splice(prevIndex, 1, currQuestionWithNewOrder);

  arrOfQuestions.splice(currentIndex, 1, prevQuestionWithNewOrder);

  const controlsWithoutQuestions = controlsWithoutValues(controls, QUESTION);
  const objectWithQuestions = convertArrayToObject(arrOfQuestions);

  return updateObject(controlsWithoutQuestions, objectWithQuestions);
};

export const addDeadlineRange = (deadlineRange = 30) => {
  const dateRangeSelect = [];
  const currentDate = new Date();
  const transformedCurrentDate = moment(currentDate);
  const neverValue = { value: null, label: "No End Date" };

  let newDate = null;
  for (let i = 0; i <= deadlineRange - 1; i += 1) {
    newDate = transformedCurrentDate.add(1, "day");
    dateRangeSelect.push({
      value: newDate.format(DATE_FORMATS.HYPHEN_SEPARATOR),
      label: newDate.format(DATE_FORMATS.SPACE_SEPARATOR_LONG_MONTH)
    });
  }

  dateRangeSelect.push(neverValue);

  return dateRangeSelect;
};

export const addRangeForChart = (range = 30) => {
  const dateRangeSelect = [];
  const currentDate = new Date();
  const transformedCurrentDate = moment(currentDate);

  let newDate = null;
  for (let i = 0; i <= range - 1; i += 1) {
    // To begin with first day we need at the first step add 0 day then 1 day
    const addOneDay = i === 0 ? 0 : 1;
    newDate = transformedCurrentDate.subtract(addOneDay, "day");
    dateRangeSelect.unshift({
      value: newDate.format(DATE_FORMATS.HYPHEN_SEPARATOR),
      label: newDate.format(DATE_FORMATS.SPACE_SEPARATOR)
    });
  }

  return dateRangeSelect;
};

export const getFileBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }
  });

export const getTextFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsText(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }
  });

export const handleClassName = (
  error = null,
  touched = null,
  type = "input-element",
  selectValue = null
) => {
  if (selectValue === null && type === "select") {
    return error && touched ? `${type} initial invalid` : `${type} initial`;
  }

  return error && touched ? `${type} invalid` : `${type}`;
};

export const calculatePercent = (current = 0, total = 0) => {
  if (!current || !total) {
    return 0;
  }

  return ((current / total) * 100).toFixed();
};

const handleIsOS = () => navigator.userAgent.match(/ipad|iphone/i);

const createTextArea = (textArea, text) => {
  textArea = document.createElement("textArea");
  textArea.value = text;
  document.body.appendChild(textArea);

  return textArea;
};

const selectText = (textArea, isOS) => {
  let range = null;
  let selection = null;

  if (isOS) {
    range = document.createRange();
    range.selectNodeContents(textArea);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
    return textArea;
  }

  textArea.select();
  return textArea;
};

export const copyToClipboard = text => {
  const isOS = handleIsOS();
  let textArea;

  textArea = createTextArea(textArea, text);
  textArea = selectText(textArea, isOS);

  document.execCommand("copy");
  document.body.removeChild(textArea);
};

export const copyTextFromButton = ref => {
  if (ref) {
    const text = ref.current.innerText || ref.current.value;

    copyToClipboard(text);
  }
};

export const copyTextToClipboard = text => {
  if (navigator?.clipboard?.writeText) {
    navigator.clipboard.writeText(text);
  }
};

export const isLastElement = (index, arr = []) => index === arr.length - 1;

export const handleQueryParams = search =>
  search
    .replace("?", "")
    .split("&")
    .reduce((res, item) => {
      const [name, val] = item.split("=");
      res[name] = decodeURIComponent(val);
      return res;
    }, {});

export const createMetaAuthor = text => {
  const isMetaExist = document.querySelectorAll("meta[name=author]").length > 0;

  if (!isMetaExist) {
    const meta = document.createElement("meta");
    meta.setAttribute("name", "author");
    meta.content = text || "";
    document.getElementsByTagName("head")[0].appendChild(meta);
  }
};

export const normalizeText = (rawText, numberOfCharacters) => {
  const stripedHtml = rawText.replace(/<[^>]+>/g, "");
  return stripedHtml.substring(0, numberOfCharacters);
};

export const createMetaDescrption = rawText => {
  const isMetaExist =
    document.querySelectorAll("meta[name=description]").length > 0;

  if (!isMetaExist) {
    const meta = document.createElement("meta");
    meta.setAttribute("name", "description");
    meta.setAttribute("property", "og:description");
    meta.content = normalizeText(rawText || "", 150);
    document.getElementsByTagName("head")[0].appendChild(meta);
  }
};

export const createMetaImage = (imgURL, defaultImg) => {
  const isMetaExist = document.querySelectorAll("meta[name=image]").length > 0;

  if (!isMetaExist) {
    const meta = document.createElement("meta");
    meta.setAttribute("name", "image");
    meta.setAttribute("property", "og:image");
    meta.content =
      imgURL || `${process.env.REACT_APP_FRONTEND_URL}${defaultImg}`;
    document.getElementsByTagName("head")[0].appendChild(meta);
  }
};

export const stringToPhoneNumber = str =>
  str.toString().replace(/\D/g, "");

export const retry = (callback, errorCallback, times = 3, action = "") => {
  let numberOfTries = 0;
  return new Promise(async resolve => {
    try {
      // eslint-disable-next-line no-plusplus
      numberOfTries++;
      await callback();
    } catch (e) {
      console.log(
        `Unsuccessful, retried ${action} ${numberOfTries} times... ${e}`
      );

      const interval = setInterval(async () => {
        // eslint-disable-next-line no-plusplus
        numberOfTries++;
        if (numberOfTries === times) {
          console.log(`Trying ${action} for the last time... (${times})`);
          clearInterval(interval);
        }
        try {
          await callback();
          clearInterval(interval);
          console.log(
            `Operation successful, retried ${action} ${numberOfTries} times.`
          );
          resolve();
        } catch (err) {
          if (numberOfTries === times && typeof errorCallback === "function") {
            await errorCallback();
          }
          console.log(
            `Unsuccessful, retried ${action} ${numberOfTries} times... ${err}`
          );
        }
      }, 2000);
    }
  });
};

export const hasExistingIdvCheck = questionsInfo => {
  let hasIdvCheck = false;

  Object.keys(questionsInfo).forEach(key => {
    if (key !== "id" && !hasIdvCheck) {
      hasIdvCheck = ALL_IDENTITY_VERIFICATION_TYPES.includes(questionsInfo?.[key]?.answer_type);
    }
  });

  return hasIdvCheck;
};

export const getQuestionTypes = (questions, answerType = "") => {
  const hasIdvCheck = questions.some(
    question =>
      question?.answer_type === IDV_TYPE || question?.answer_type === RTW_TYPE
  );

  return !hasIdvCheck || answerType === IDV_TYPE || answerType === RTW_TYPE
    ? IDV_INCLUDED_TYPES
    : TYPES;
};

export const convertToPlain = html => {
  const tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;

  return tempDivElement.textContent || tempDivElement.innerText || "";
};

export const removeDuplicates = (list, key) => [
  ...new Map(list.map(item => [item[key], item])).values()
];

export const getConnectedJobs = atsConnected =>
  atsConnected
    ?.map(ats =>
      ats.callbacks?.map(callback => ({ ...callback, atsKey: ats.key }))
    )
    ?.reduce((collection, currentValue) => collection.concat(currentValue), []);

export const getTotalJobsAndStagesCount = jobs => {
  const results = jobs?.reduce((r, a) => {
    r[a.remote_interview_id] = r[a.remote_interview_id] || [];
    r[a.remote_interview_id].push(a);
    return r;
  }, Object.create(null));

  return Object.values(results ?? {})?.length ?? 0;
};

export const getJobKeysFromJobsAndStages = jobs => {
  const results = jobs?.reduce((r, a) => {
    r[a.remote_interview_id] = r[a.remote_interview_id] || [];
    r[a.remote_interview_id].push(a);
    return r;
  }, Object.create(null));

  return Object.keys(results);
};

export const lazyRetry = componentImport => new Promise((resolve, reject) => {
  const hasRefreshed = JSON.parse(
    getIsStorageSupported() ? window.sessionStorage.getItem("retry-lazy-refreshed") : "false" || "false"
  );

  componentImport().then(component => {
    if (getIsStorageSupported()) window.sessionStorage.setItem("retry-lazy-refreshed", "false");

    resolve(component);
  // eslint-disable-next-line consistent-return
  }).catch(error => {
    if (!hasRefreshed) {
      if (getIsStorageSupported()) window.sessionStorage.setItem("retry-lazy-refreshed", "true");

      return window.location.reload();
    }

    reject(error);
  });
});


export const getAvatarName = (userName, email) => {
  if (userName) {
    const name = userName.split(" ");
    if (name.length > 1) return `${name[0].charAt(0)}${name[name.length - 1].charAt(0)}`;

    return `${name?.[0].charAt(0)}`;
  }

  return email.slice(0, 1);
};

export const computeTimePassed = date => {
  const now = moment();
  const givenDate = moment(date);
  const diffInSeconds = now.diff(givenDate, "seconds");
  const diffInMinutes = now.diff(givenDate, "minutes");
  const diffInHours = now.diff(givenDate, "hours");
  const diffInDays = now.diff(givenDate, "days");
  const diffInWeeks = now.diff(givenDate, "weeks");

  if (diffInSeconds < 60) {
    return "few sec ago";
  } if (diffInMinutes < 60) {
    return `${diffInMinutes } mins ago`;
  } if (diffInHours < 24) {
    return `${diffInHours } hrs ago`;
  } if (diffInDays < 7) {
    return `${diffInDays } days ago`;
  } if (diffInWeeks < 1) {
    return `${diffInWeeks } weeks ago`;
  }
  return givenDate.fromNow();
};

export const isSidebarEnabled = process.env.REACT_APP_SIDEBAR_ENABLED === "true";

export const phoneParser = (setFieldValue, e) => {
  const beginning = e?.length >= 3 ? e.slice(0, 3) : e;

  if (beginning === "+00") {
    const value = e.replace("+00", "+");

    setFieldValue("phone", value);
    return;
  }

  setFieldValue("phone", e);
};

export const isTestPOEditorEnabled = process.env.REACT_APP_TEST_POEDITOR === "true";
