import React, { useState, useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Formik } from "formik";
import classNames from "classnames";
import { confirmable } from "react-confirm";
import { Modal } from "react-bootstrap";
import pick from "lodash/pick";
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { handleClassName, phoneParser } from "mixins/helpers";
import { initialStateVideoAnswersConfirm, schemaVideoAnswersConfirm } from "mixins/helperCandidate";
import { Profile, Mail } from "mixins/svgIcons";
import { getCandidateJobInfo } from "store/modules/сandidates/selectors";
import { store } from "store";
import { getFontColorFromBgColor } from "mixins/helperDynamicStyles";

const Details = ({
  t,
  show,
  proceed,
  dismiss,
  confirmation: {
    phone = "",
    email = "",
    full_name: fullName = "",
    location: {
      country_code: countryCode = ""
    }
  },
  removeModal,
  buttonColor,
  onSubmit
}) => {
  const [country, setCountry] = useState(countryCode || (phone ? phone.split(" ")[0] : undefined));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const jobInfo = getCandidateJobInfo(store.getState());

  const memoizedConfirmation = useMemo(() => ({
    phone,
    email,
    full_name: fullName
  }), [phone, email, fullName]);

  return (
    <Modal
      dialogClassName="candidate-details"
      show={show}
      onHide={() => {
        dismiss();
        removeModal();
      }}
    >
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={{
            ...initialStateVideoAnswersConfirm,
            ...memoizedConfirmation
          }}
          validationSchema={schemaVideoAnswersConfirm({
            requirePhone: !!jobInfo?.is_candidate_phone_number_required
          })}
          onSubmit={async values => {
            setIsSubmitting(true);

            await onSubmit({
              values,
              successCallback: () => {
                setIsSubmitting(false);
                proceed(pick(values, ["full_name", "email", "phone"]));
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            isValidating,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue
          }) => (
            <form className="form__candidate-details" onSubmit={handleSubmit}>
              <h3 className="form__candidate-details-title">
                {t("modals.candidateDetails.header")}
              </h3>
              <p className="form__candidate-details-subtitle">
                {t("modals.candidateDetails.text")}
              </p>
              <div className="input">
                <label htmlFor="candidate-name" className="label">
                  {t("input.fullNameLabel")}
                </label>

                <div className="input-wrapper">
                  <Profile svgIconClass="input-icon" />
                  <input
                    id="candidate-name"
                    type="text"
                    name="full_name"
                    placeholder={t("input.fullNamePlaceholder")}
                    className={handleClassName(
                      errors.full_name,
                      touched.full_name
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.full_name}
                    disabled={!!email}
                  />

                  {errors.full_name && touched.full_name && (
                    <p className="input-error-message">
                      {t("input.fullNameError")}
                    </p>
                  )}
                </div>
              </div>
              <div className="input">
                <label htmlFor="candidate-email" className="label">
                  {t("input.emailLabel")}
                </label>

                <div className="input-wrapper">
                  <Mail svgIconClass="input-icon" />
                  <input
                    id="candidate-email"
                    type="text"
                    name="email"
                    placeholder={t("input.emailPlaceholder")}
                    className={handleClassName(errors.email, touched.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    disabled={!!email}
                  />

                  {errors.email && touched.email && (
                    <p className="input-error-message">{t("input.emailError")}</p>
                  )}
                </div>
              </div>
              <div className="input">
                <label htmlFor="candidate-phone" className="label">
                  {t("input.phoneLabel")}
                </label>

                <div className="input-wrapper">
                  <PhoneInput
                    international
                    defaultCountry={country}
                    value={values.phone || ""}
                    onChange={value => {
                      if (isValidPhoneNumber(String(value))) {
                        const phoneNumber = parsePhoneNumber(String(value));
                        setCountry(phoneNumber?.country);
                      }

                      phoneParser(setFieldValue, value || "");
                    }}
                    onBlur={handleBlur}
                    name="phone"
                    id="candidate-phone"
                    placeholder={t("input.phonePlaceholder")}
                    className={classNames(
                      "PhoneInput without-icon",
                      handleClassName(errors.phone, touched.phone)
                    )}
                    error={errors.phone && touched.phone ? errors.phone : undefined}
                  />

                  {errors.phone && touched.phone && (
                    <p className="input-error-message">{t("input.phoneError")}</p>
                  )}
                </div>
              </div>

              <div className="form__candidate-details-button">
                <button
                  disabled={isValidating}
                  type="submit"
                  style={{
                    background: buttonColor,
                    color: getFontColorFromBgColor({ hex: buttonColor })
                  }}
                  className="
                    n-button__large
                    n-bg-purple-100
                    n-white
                  "
                >
                  {t(`button.${isSubmitting ? "submitting" : "submit"}`)}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

Details.defaultProps = {
  show: undefined,
  proceed: undefined,
  dismiss: undefined,
  removeModal: undefined,
  confirmation: {},
  buttonColor: undefined,
  onSubmit: () => {}
};

Details.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool,
  proceed: PropTypes.func,
  dismiss: PropTypes.func,
  removeModal: PropTypes.func,
  confirmation: PropTypes.shape({
    full_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    location: PropTypes.shape({
      country_code: PropTypes.string
    })
  }),
  buttonColor: PropTypes.string,
  onSubmit: PropTypes.func
};

export default confirmable((withTranslation()(Details)));
