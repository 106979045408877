/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-confusing-arrow */
import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";

import CANDIDATE_STATUSES from "configs/candidates";
import withLoaderScreen from "hoc/withLoaderScreen";
import { history } from "store";

import {
  updateDetailsConfirmation,
  warningConfirmation,
  deleteConfirmationCompany,
  deleteDefaultCompany,
  deleteConfirmation,
  cardIsLockedConfirmation,
  previewTemplate,
  spamWarning,
  unsavedQuestion
} from "utils/confirm";

const Modals = ({
  t,
  modalInfo: {
    type = "",
    cancelPath = "",
    candidateInfo: {
      userId = "",
      name = "",
      email = "",
      phone = "",
      showCalendar = true,
      companyInfo = {},
      calendarDates = [],
      position,
      location = {}
    } = {},
    videoConfirmation = {},
    inviteConfirmation = {},
    jobConfirmation = {},
    userConfirmation = {},
    companyConfirmation = {},
    handleConfirm,
    libraryConfirmation = {},
    templateInfo,
    saveLibrary,
    fetchCandidate,
    cancelFetch,
    idvConfirmation = {}
  } = {},
  updateCandidateInfo,
  deleteJobInvite,
  deleteJob,
  deleteCompany,
  deleteUser,
  removeModal,
  removeDevice,
  deleteLibrary
}) => {
  useEffect(() => {
    if (type === "isLockedCard") {
      cardIsLockedConfirmation(null, {
        removeModal,
        cancelPath
      });
    }

    if (type === "saveCandidateInfo") {
      const dateForInvites = calendarDates.map(date =>
        moment(date)
          .utc()
          .format());

      if (name && email && phone) {
        updateCandidateInfo({
          full_name: name || "",
          email: email || "",
          phone: phone || "",
          position,
          userId,
          showCalendar,
          status: CANDIDATE_STATUSES.RECEIVED,
          availabilities: dateForInvites
        });
      } else {
        updateDetailsConfirmation(
          {
            full_name: name || "",
            email: email || "",
            phone: phone || "",
            location: location || {}
          },
          {
            buttonColor: companyInfo.button_color,
            removeModal,
            onSubmit: async ({ values, successCallback }) => {
              try {
                await updateCandidateInfo({
                  ...values,
                  userId,
                  showCalendar,
                  status: CANDIDATE_STATUSES.RECEIVED,
                  availabilities: dateForInvites,
                  position,
                  successCallback: () => {
                    if (typeof videoConfirmation?.onSave === "function") videoConfirmation.onSave();
                    successCallback();
                  }
                });
              } catch (error) {
                console.log("error", error);
              }
            }
          }
        ).then(
          () => history.push(`/candidate/complete/${userId}`),
          () => {
            console.log("cancel!");
          }
        );
      }
    }

    if (type === "lostVideoConfirmation") {
      const { goToNextQuestion = null, questionId = "" } = videoConfirmation;
      const confirmationText = t("modals.lostVideo.answer");

      warningConfirmation(confirmationText, {
        btnCancelText: "no",
        btnAcceptText: "yes",
        removeModal
      }).then(
        () => goToNextQuestion(questionId),
        () => console.log("cancel!")
      );
    }

    if (type === "deleteInviteConfirmation") {
      const title = t("modals.deleteInvitation.title");
      const confirmationText = t("modals.deleteInvitation.text");
      const { value } = inviteConfirmation;

      deleteConfirmation(confirmationText, { title, removeModal }).then(
        () => {
          deleteJobInvite(value);
        },
        () => {
          console.log("cancel!");
        }
      );
    }

    if (type === "deleteJobConfirmation") {
      const title = t("modals.jobDelete.title");
      const confirmationText = t("modals.jobDelete.text");
      const { jobId, queryParam } = jobConfirmation;

      deleteConfirmation(confirmationText, { title, removeModal }).then(
        () => {
          deleteJob({ jobId, queryParam });
        },
        () => {
          console.log("cancel!");
        }
      );
    }

    if (type === "deleteLibraryConfirmation") {
      const title = t("modals.libraryDelete.title");
      const { key } = libraryConfirmation;

      deleteConfirmation("", { title, removeModal }).then(
        () => deleteLibrary(key),
        () => console.log("cancel!")
      );
    }

    if (type === "deleteUserConfirmation") {
      const title = t("modals.deleteUser.title");
      const confirmationText = t("modals.deleteUser.text");
      const { value } = userConfirmation;

      deleteConfirmation(confirmationText, { title, removeModal }).then(
        () => {
          deleteUser(value);
        },
        () => {
          console.log("cancel!");
        }
      );
    }

    if (type === "deleteCompanyConfirmation") {
      const confirmationText = t("modals.deleteCompany.text");
      const { value } = companyConfirmation;

      deleteConfirmationCompany(confirmationText, { removeModal }).then(
        () => {
          deleteCompany(value);
        },
        () => {
          console.log("cancel!");
        }
      );
    }
    if (type === "deleteDefaultCompany") {
      deleteDefaultCompany(t("modals.deleteDefaultCompany.text"), { removeModal }).then(
        () => {
          console.log("cancel!");
        }
      );
    }

    if (type === "deleteSecurityConfirmation") {
      const title = t("modals.securityDelete.title");
      const confirmationText = t("modals.securityDelete.text");

      deleteConfirmation(confirmationText, {
        title,
        removeModal,
        btnAcceptText: "disable"
      }).then(
        () => {
          removeDevice();
        },
        () => {
          console.log("cancel!");
        }
      );
    }

    if (type === "deleteInterviewConfirmation") {
      const title = t("modals.interviewDelete.title");

      deleteConfirmation("", {
        title,
        removeModal,
        btnAcceptText: "delete"
      }).then(handleConfirm, () => {
        console.log("cancel!");
      });
    }
    if (type === "previewTemplate") {
      previewTemplate(templateInfo.label, {
        templateInfo,
        removeModal
      });
    }
    if (type === "spamWarning") {
      spamWarning("", {
        removeModal,
        saveLibrary
      });
    }
    if (type === "unsavedQuestion") {
      unsavedQuestion("", {
        removeModal,
        fetchCandidate,
        cancelFetch
      });
    }

    if (type === "idvSessionExpired") {
      const confirmationText = t("modals.error.pleaseReload");

      warningConfirmation(confirmationText, {
        btnAcceptText: "reloadPage",
        removeModal
      }).then(
        () => {
          window.location.replace(window.location.href.replace("/idv-check", ""));
        }
      );
    }

    if (type === "idvSubscriptionDismissed") {
      const confirmationText = t("modals.error.idv.warning");
      const { onCancel, onProceed } = idvConfirmation;

      const handleRemoveModal = () => {
        onProceed();
        removeModal();
      };

      warningConfirmation(confirmationText, {
        btnAcceptText: "proceed",
        removeModal: handleRemoveModal
      }).then(
        () => onProceed(),
        () => onCancel()
      );
    }

    if (type === "generateNewIntegrationKey") {
      const confirmationText = t("modals.marketplace.generateNewKey");
      const { onProceed } = idvConfirmation;

      const handleRemoveModal = () => {
        removeModal();
      };

      warningConfirmation(confirmationText, {
        btnAcceptText: "confirm",
        removeModal: handleRemoveModal
      }).then(
        () => onProceed(),
        () => handleRemoveModal()
      );
    }

    if (type === "resetAtsJobsAndStages") {
      const confirmationText = t("modals.connectToAts.reset");
      const { onProceed } = userConfirmation;

      const handleRemoveModal = () => {
        removeModal();
      };

      warningConfirmation(confirmationText, {
        btnAcceptText: "confirm",
        removeModal: handleRemoveModal
      }).then(
        () => onProceed(),
        () => handleRemoveModal()
      );
    }

    if (type === "deleteWebhookLog") {
      const confirmationText = t("modals.webhooks.delete");
      const { onProceed } = userConfirmation;

      const handleRemoveModal = () => {
        removeModal();
      };

      warningConfirmation(confirmationText, {
        btnAcceptText: "delete",
        removeModal: handleRemoveModal
      }).then(
        () => onProceed(),
        () => handleRemoveModal()
      );
    }
  }, [type]);

  return null;
};

Modals.defaultProps = {
  modalInfo: undefined,
  history: undefined,
  removeModal: undefined,
  updateCandidateInfo: undefined,
  setModal: undefined,
  removeDevice: undefined
};

Modals.propTypes = {
  modalInfo: PropTypes.shape({
    type: PropTypes.string,
    cancelPath: PropTypes.string
  }),
  history: PropTypes.shape({}),
  removeModal: PropTypes.func,
  updateCandidateInfo: PropTypes.func,
  setModal: PropTypes.func,
  removeDevice: PropTypes.func
};

export default withLoaderScreen(withTranslation()(Modals));
