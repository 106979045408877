/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useRef } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { store } from "store";
import { saveCurrentQuestion, saveOnAmazonS3 } from "store/modules/сandidates/actions";

const MediaRecorder = props => {
  const { userId, jobId, questionId } = props?.match?.params ?? {};
  const [audioDevices, setAudioDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedAudio, setSelectedAudio] = useState("");
  const [selectedVideo, setSelectedVideo] = useState("");
  const videoPreviewRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const streamRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState("");

  const videoConstraints = {
    width: { ideal: 300 },
    height: { ideal: 200 }
  };

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    clearBlobUrl,
    previewStream
  } = useReactMediaRecorder({
    video: selectedVideo ? {
      deviceId: selectedVideo,
      ...videoConstraints
    } : { ...videoConstraints },
    audio: selectedAudio ? {
      deviceId: selectedAudio
    } : true,
    onStop: (blobUrl, blob) => {
      const videoBlob = new Blob([blob], { type: "video/webm;codecs=vp8,opus" });
      setRecordedChunks([videoBlob]);

      const blob1 = new Blob([videoBlob], {
        type: "video/webm"
      });

      const dataToUpload = {
        blob: blob1,
        jobId,
        questionId,
        userId,
        questionNumber: 1
      };

      store.dispatch(
        saveOnAmazonS3({
          ...dataToUpload,
          spentTime: 0,
          cancelToken: "",
          callback: val => {
            if (!val.response.remote_link) return;
            setVideoUrl(val.response.remote_link);
          }
        })
      );

      store.dispatch(
        saveCurrentQuestion({
          userId: "b71fa7fc755f4be6a68f4347a1009e07",
          questionCurrentCashed: dataToUpload
        })
      );
    },
    mediaRecorderOptions: {
      mimeType: "video/webm;codecs=vp8,opus"
    }
  });

  useEffect(() => {
    if (!videoPreviewRef.current) return;

    if (previewStream && !mediaBlobUrl) {
      // Only set if stream has changed
      if (streamRef.current !== previewStream) {
        videoPreviewRef.current.srcObject = previewStream;
        streamRef.current = previewStream;
      }
    } else if (!previewStream && !mediaBlobUrl) {
      // Clear the video when there's no stream and no recorded video
      videoPreviewRef.current.srcObject = null;
      streamRef.current = null;
    }
  }, [previewStream, mediaBlobUrl]);

  useEffect(() => {
    const getDevices = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true
        });
        videoPreviewRef.current.srcObject = stream;
        streamRef.current = stream;

        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioInputs = devices.filter(device => device.kind === "audioinput");
        const videoInputs = devices.filter(device => device.kind === "videoinput");

        setAudioDevices(audioInputs);
        setVideoDevices(videoInputs);

        if (audioInputs.length) setSelectedAudio(audioInputs[0].deviceId);
        if (videoInputs.length) setSelectedVideo(videoInputs[0].deviceId);
      } catch (err) {
        console.error("Error accessing media devices:", err);
      }
    };

    getDevices();

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const handleStartRecording = () => {
    setRecordedChunks([]);
    startRecording();
  };

  const handleStopRecording = () => {
    stopRecording();
  };

  const handleDownload = () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "recording.webm";
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };
  const VideoPreview = React.memo(() => (
    <video
      ref={videoPreviewRef}
      autoPlay
      playsInline
      muted
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        backgroundColor: "#000"
      }}
    />
  ));

  const RecordedVideo = React.memo(({ src }) => (
    <video
      src={src}
      controls
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        backgroundColor: "#000"
      }}
    />
  ));

  return (
    <div className="p-4 max-w-xl mx-auto">
      <div className="mb-4 space-y-4">
        <div>
          <label className="block text-sm font-medium mb-2">Microphone</label>
          <select
            value={selectedAudio}
            onChange={e => setSelectedAudio(e.target.value)}
            className="w-full p-2 border rounded-md bg-white"
            disabled={status === "recording"}
          >
            {audioDevices.map(device => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || `Microphone ${audioDevices.indexOf(device) + 1}`}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium mb-2">Camera</label>
          <select
            value={selectedVideo}
            onChange={e => setSelectedVideo(e.target.value)}
            className="w-full p-2 border rounded-md bg-white"
            disabled={status === "recording"}
          >
            {videoDevices.map(device => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || `Camera ${videoDevices.indexOf(device) + 1}`}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="relative" style={{ width: "300px", height: "200px", position: "relative" }}>
        {videoUrl ? (
          <RecordedVideo src={videoUrl} />
        ) : (
          <VideoPreview />
        )}
      </div>

      <div className="flex gap-2 justify-center mt-4">
        {status !== "recording" ? (
          <button
            onClick={handleStartRecording}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Start Recording
          </button>
        ) : (
          <button
            onClick={handleStopRecording}
            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Stop Recording
          </button>
        )}

        {mediaBlobUrl && recordedChunks.length > 0 && (
          <>
            <button
              onClick={handleDownload}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Download
            </button>
            <button
              onClick={clearBlobUrl}
              className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Clear
            </button>
          </>
        )}
      </div>

      <div className="text-center mt-4 text-sm text-gray-500">
        Status:
        {" "}
        {status}
      </div>
    </div>
  );
};

export default MediaRecorder;
