/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isFirefox, isMobileSafari, isMobileOnly, isTablet, isChrome } from "react-device-detect";

import { checkIfThisCurrentQuestion, canPlayExtension } from "mixins/helperVideoRecording";
import Message from "components/Common/Message";

import "./styles.scss";
import useInterviewStore from "store/interviewStore";
import { BeatLoader } from "react-spinners";

const PlayerMobile = ({
  t,
  videoBlob,
  videoPlayerRef,
  videoInputRef,
  question,
  savedAnswers,
  onFileUpload,
  cashedVideo,
  remoteLink,
  isEBMLCalled = false,
  hasEBML = true
}) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const { isUploading } = useInterviewStore();

  const { isCurrentQuestionSavedAnswer, savedAnswer } = checkIfThisCurrentQuestion(
    savedAnswers,
    question
  );

  useEffect(() => {
    if (!videoBlob && !isCurrentQuestionSavedAnswer) {
      setShowPlayer(false);
      return;
    }

    setShowPlayer(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedAnswers, videoBlob, question.key]);

  const canPlay = useMemo(() => {
    if (!savedAnswer?.media_extension || videoBlob) return "supported";
    return canPlayExtension(savedAnswer?.media_extension.toLowerCase());
  }, [savedAnswer, videoBlob]);

  const src = useMemo(
    () => {
      const link = remoteLink && isCurrentQuestionSavedAnswer
        ? remoteLink : cashedVideo;

      if (remoteLink) {
        return `${link}#t=0.001?${savedAnswer?.updated_at ?? new Date().toDateString()}`;
      }

      if (isMobileSafari || isFirefox || (isChrome && cashedVideo)) {
        return link;
      }

      return link && `${link}#t=0.001`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [remoteLink, cashedVideo, savedAnswer]
  );

  const videoProps = useMemo(
    () => {
      switch (true) {
      /*
            isMobile includes isMobileOnly + isTablet
            but isChrome && isMobile does not work
         */
      case isChrome && (isMobileOnly || isTablet):
        return {
          autoplay: "autoplay",
          preload: "metadata"
        };
      case (isMobileSafari || isFirefox):
        return {
          autoplay: "autoplay"
        };
      default:
        return {};
      }
    },
    []
  );

  const [height, setHeight] = useState("240px");

  const handleMetadata = event => {
    const { videoWidth } = event?.target || {};
    const clientHeight = `${Math.ceil(9 * videoWidth / 16)}px`;
    setHeight(clientHeight);
  };

  const style = { height };

  if (isUploading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <BeatLoader color="#BBC2C9" size={6} margin={1} loading />
      </div>
    );
  }

  return (
    <>
      <input
        ref={videoInputRef}
        type="file"
        accept="video/*"
        capture="user"
        className="mobile-player__video-input"
        onChange={onFileUpload}
      />

      {showPlayer && (
        <>
          {canPlay ? null : <Message error message={t("errors.previewUnavailable")} />}
          {isEBMLCalled && !hasEBML ? (<Message
            error
            message={t("errors.ebmlError.0") + t("errors.ebmlError.1") + t("errors.ebmlError.2") + t("errors.ebmlError.3")}
          />) : null}
          <div className="mobile-player" style={style}>
            <video
              {...videoProps}
              ref={videoPlayerRef}
              src={src}
              controls={src}
              volume={1}
              muted
              onLoadedMetadata={handleMetadata}
              style={style}
            />
          </div>
        </>
      )}
    </>
  );
};

PlayerMobile.defaultProps = {
  question: {},
  savedAnswers: [],
  cashedVideo: undefined,
  remoteLink: undefined,
  videoBlob: undefined,
  videoPlayerRef: undefined,
  videoInputRef: undefined,
  onFileUpload: undefined,
  isEBMLCalled: false,
  hasEBML: true
};

PlayerMobile.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    key: PropTypes.string
  }),
  savedAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      remote_link: PropTypes.string
    })
  ),
  remoteLink: PropTypes.string,
  cashedVideo: PropTypes.string,
  videoBlob: PropTypes.shape({}),
  videoPlayerRef: PropTypes.shape({
    current: PropTypes.object
  }),
  videoInputRef: PropTypes.shape({
    current: PropTypes.object
  }),
  onFileUpload: PropTypes.func,
  isEBMLCalled: PropTypes.bool,
  hasEBML: PropTypes.bool
};

export default withTranslation()(PlayerMobile);
