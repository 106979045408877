import enTranslation from "./locales/en.json";
import arTranslation from "./locales/ar.json";

export const DEFAULT_LANGUAGE = "en";

export default [
  {
    value: "en",
    text: "English (EN)",
    translation: enTranslation
  },
  {
    value: "ar",
    text: "العربية (AR)",
    translation: arTranslation
  }
];
